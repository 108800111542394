<template>
  <div class="questionnaire_container">
    <div class="header" v-if="query.type === '0'">
      <van-field readonly clickable label="选择就诊人" :value="patName" @click="patNamePickerShow = true"
        right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow" round position="bottom">
        <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="template" v-if="templates.configQuestionRecord">
      <div class="tit">{{ templates.configQuestionRecord.questionName }}</div>
      <form @submit.prevent="formSubmit" autocomplete="off">
        <div class="list">
          <div v-for="(item, index) in templates.configQuestionRecord.configQuestionnaireList" :key="index">
            <div class="item">
              <div class="title">
                <span class="require" v-if="item.isRequired === 0">*</span>
                <span>{{ item.orderNo }}、{{ item.questionTitle }}</span>
              </div>
              <!-- 文本框 -->
              <div v-if="item.controlType === 1">
                <div class="input" v-for="(itemTwo, index) in item.configQuestionDetailList" :key="index">
                  <span>{{ itemTwo.itemName }}：</span>
                  <input placeholder="请输入"
                    :name="'question_' + itemTwo.questionId + '_' + itemTwo.itemOrderNum + item.isRequired + item.it + itemTwo.controlType"
                    :value="itemTwo.value">
                </div>
              </div>
              <!-- 单选框 -->
              <div v-else-if="item.controlType === 3">
                <div class="options" v-for="(itemTwo, index) in item.configQuestionDetailList" :key="index">
                  <div v-if="itemTwo.controlType !== 9">
                    <label>
                      <!-- 单选按钮 -->
                      <input type="radio" :value="itemTwo.itemOrderNum"
                        :name="'question_' + item.id + '_' + item.isRequired + item.it + item.controlType">
                      <span>{{ itemTwo.itemName }}</span>
                    </label>
                  </div>
                  <div v-else-if="itemTwo.controlType === 9" class="diy">
                    <div v-for="(itemThree, index) in itemTwo.diyControlls" :key="index" class="diy_label">
                      <!-- 文本 -->
                      <div v-if="itemThree.controlType === 1">
                        <input class="diy_input"
                          :name="'question_' + itemTwo.questionId + '_' + itemTwo.itemOrderNum + '_9:' + itemThree.itemOrderNum + itemTwo.itemOrderNum + item.it + itemTwo.controlType">
                      </div>
                      <div class="diy_text" v-else-if="itemThree.controlType === 6">{{ itemThree.itemName }}</div>
                      <div v-else-if="itemThree.controlType === 3">
                        <div class="options">
                          <label>
                            <input class="diy_radio" type="radio" :value="itemTwo.itemOrderNum"
                              :name="'question_' + item.id + '_' + item.isRequired + item.it + item.controlType">
                            <span>{{ itemThree.itemName }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="submit" value="提    交" class="submit">
      </form>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Questionnaire",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      templates: {},
      questionLength: 0,
      answer: "",
      query: {}
    };
  },
  created () {
    this.query = this.$route.query;
    if (this.query.patNameIndex) {
      this.patNameIndex = this.query.patNameIndex;
    } else {
      this.patNameIndex = 0;
    }
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.template();
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    //获取问卷模板
    async template () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        questionCode: "1001",
        patCardNo: this.patNameArray[this.patNameIndex].pat_card_no
      });
      const { data: res } = await formPost(
        "/wxapp/questionRecord/questionRecord/template",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        let questions = res.data;
        let info = res.data.patInfo;
        let questionsLength = 0;
        if (info.cardNo === "") {
          info.cardNo = "无";
        }
        for (
          let i = 0;
          i < questions.configQuestionRecord.configQuestionnaireList.length;
          i++
        ) {
          let configQuestionnaireList =
            questions.configQuestionRecord.configQuestionnaireList[i];
          if (
            configQuestionnaireList.isRequired === 0 &&
            configQuestionnaireList.controlType === 1
          ) {
            questionsLength =
              questionsLength +
              configQuestionnaireList.configQuestionDetailList.length;
          } else {
            questionsLength++;
          }
          questions.configQuestionRecord.configQuestionnaireList[i].it = i;
        }
        for (
          let i = 0;
          i < questions.configQuestionRecord.configQuestionnaireList.length;
          i++
        ) {
          if (
            questions.configQuestionRecord.configQuestionnaireList[i]
              .questionTitle === "基本信息"
          ) {
            for (
              let j = 0;
              j <
              questions.configQuestionRecord.configQuestionnaireList[i]
                .configQuestionDetailList.length;
              j++
            ) {
              if (
                questions.configQuestionRecord.configQuestionnaireList[i]
                  .configQuestionDetailList[j].itemName === "姓名"
              ) {
                questions.configQuestionRecord.configQuestionnaireList[
                  i
                ].configQuestionDetailList[j].value = info.name;
              } else if (
                questions.configQuestionRecord.configQuestionnaireList[i]
                  .configQuestionDetailList[j].itemName === "联系电话"
              ) {
                questions.configQuestionRecord.configQuestionnaireList[
                  i
                ].configQuestionDetailList[j].value = info.telephone;
              } else if (
                questions.configQuestionRecord.configQuestionnaireList[i]
                  .configQuestionDetailList[j].itemName === "身份证号码"
              ) {
                questions.configQuestionRecord.configQuestionnaireList[
                  i
                ].configQuestionDetailList[j].value = info.cardNo;
              } else if (
                questions.configQuestionRecord.configQuestionnaireList[i]
                  .configQuestionDetailList[j].itemName === "现居住地址"
              ) {
                questions.configQuestionRecord.configQuestionnaireList[
                  i
                ].configQuestionDetailList[j].value = info.address;
              }
            }
            break;
          }
        }
        this.templates = questions;
        this.questionLength = questionsLength;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //提交问卷
    async submit () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/questionAnswer/save", {
        answer: this.answer
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success("提交成功");
        setTimeout(() => {
          if (this.query.type === "0") {
            this.$router.push({
              path: "/"
            });
          } else {
            this.lockReg();
          }
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //锁定号源
    async lockReg () {
      this.patNameIndex = parseInt(this.query.patNameIndex);
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/appointmentRecord/record/lockReg",
        {
          beginTime: this.query.beginTime,
          deptCode: this.query.deptCode,
          deptName: this.query.deptName,
          doctorCode: this.query.doctorCode,
          endTime: this.query.endTime,
          hospitalId: 100,
          medicalCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          openId: window.localStorage.getItem("openId"),
          regFee: this.query.regFee,
          scheduleDate: this.query.timeSlot,
          timeFlag: this.query.timeFlag,
          doctorName: this.query.doctorName
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        if (res.data.isPay === 6) {
          if (this.query.doctorCode === "111111") {
            this.$router.push({
              path: "/outpatient/detail",
              query: {
                orderNum: res.data.orderNum,
                patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
                patCardType: this.patNameArray[this.patNameIndex].pat_card_type
              }
            });
          } else {
            this.$router.push({
              path: "/register/status",
              query: {
                isPay: res.data.isPay,
                name: this.query.name,
                deptName: this.query.deptName,
                doctorName: this.query.doctorName,
                timeSlot: this.query.timeSlot,
                beginTime: this.query.beginTime,
                endTime: this.query.endTime,
                regFee: this.query.regFee,
                medicalCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
                position: this.query.position
              }
            });
          }
        } else {
          this.$router.push({
            path: "/register/detail",
            query: {
              isPay: res.data.isPay,
              appointmentId: res.data.appointmentId,
              orderNum: res.data.orderNum,
              orderRecordId: res.data.orderRecordId,
              name: this.query.name,
              deptName: this.query.deptName,
              doctorName: this.query.doctorName,
              timeSlot: this.query.timeSlot,
              beginTime: this.query.beginTime,
              endTime: this.query.endTime,
              regFee: this.query.regFee,
              medicalCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
              position: this.query.position
            }
          });
        }
      } else {
        this.patName = "";
        this.patNameList = [];
        this.patNameArray = [];
        this.patNameIndex = 0;
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.template();
    },
    formSubmit (event) {
      let obj = {};
      let list = [];
      let all = [];
      let formData = new FormData(event.target);
      for (let [a, b] of formData) {
        obj.key = a;
        obj.value = b;
        let obj1 = {};
        obj1.key = obj.key;
        obj1.value = obj.value;
        all.push(obj1);
        let type = obj.key.substr(obj.key.length - 1, 1);
        let isRequired = obj.key.substr(obj.key.length - 3, 1);
        if (isRequired === "0") {
          let obj2 = {};
          obj2.key = obj.key;
          obj2.value = obj.value;
          list.push(obj2);
        }
      }
      if (list.length !== this.questionLength) {
        this.$toast.fail("请完成答卷");
      } else {
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < all.length; j++) {
            if (
              list[i].key.substr(list[i].key.length - 1, 1) === "3" &&
              list[i].key.substr(list[i].key.length - 3, 1) === "0"
            ) {
              if (
                list[i].value === all[j].key.substr(all[j].key.length - 3, 1) &&
                list[i].key.substr(list[i].key.length - 5, 1) ===
                all[j].key.substr(all[j].key.length - 10, 1)
              ) {
                if (all[j].value === "") {
                  this.$toast.fail("请完成答卷");
                  return;
                }
              }
            }
          }
        }
        let sub = {};
        let newKey = "";
        for (let i = 0; i < all.length; i++) {
          all[i].key = all[i].key.substring(0, all[i].key.length - 3);
          newKey = all[i].key;
          sub[newKey] = all[i].value;
        }
        sub.appType = "3";
        sub.openId = window.localStorage.getItem("openId");
        sub.patCardNo = this.templates.patInfo.patCardNo;
        sub.questionRecordCode = "1001";
        this.answer = JSON.stringify(sub);
        this.submit();
      }
    }
  }
};
</script>
<style scope>
.questionnaire_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.questionnaire_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.questionnaire_container .header .van-cell::after {
  border: 0;
}

.questionnaire_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.questionnaire_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.questionnaire_container .header .van-icon {
  font-size: 40px;
}

.questionnaire_container .header .van-picker-column {
  font-size: 40px;
}

.questionnaire_container .header .van-picker__cancel,
.questionnaire_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.template {
  padding: 0 50px;
  font-size: 36px;
  padding-bottom: 170px;
}

.template .tit {
  font-size: 40px;
  text-align: center;
  padding: 24px 0;
}

.template .item .title {
  position: relative;
}

.template .item .title .require {
  position: absolute;
  left: -34px;
  top: 0;
  color: red;
  font-size: 64px;
}

.template .item .input {
  display: flex;
  margin: 26px 0;
}

.template .item .input span {
  width: 40%;
}

.template .item .input input {
  padding-left: 10px;
  width: 60%;
}

.template .item .options {
  margin: 26px 0;
  position: relative;
}

.template .item .options span {
  margin-left: 20px;
}

.template .item .options .diy {
  display: flex;
  flex-wrap: wrap;
}

.template .item .options .diy input {
  width: 90%;
  padding-left: 10px;
  margin-left: 40px;
  transform: translateY(4px);
}

.template .item .options .diy .diy_label {
  height: 60px;
}

.template .item .options .diy .diy_input {
  margin-top: -2px;
}

.template .item .options .diy .diy_text {
  padding-left: 40px;
}

.template .item .options .diy .diy_radio {
  position: absolute;
  top: -23px;
  left: -38px;
}

.template .submit {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #1989fa;
  color: white;
  border: unset;
  font-size: 40px;
}

input[type='radio'] {
  width: 40px !important;
  height: 40px !important;
  transform: translateY(8px);
}

input[type='radio']:after {
  width: 40px !important;
  height: 40px !important;
  transform: translateY(8px);
}
</style>